import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Container, Grid, Box } from '@mui/material';
import { getBackgroundColor, getTextColor, getFontWeight } from "../../properties/ui-properties";
import { Link } from 'react-router-dom';

export default function CreateColoring({ handleChooseMerchandiseType, selectedOption }) {
  const coloringOptions = [    
    {
      id: "coloring",
      name: "Coloring Pages",
      simpleName: "coloring",
      displayName: "Coloring Page",
      image: "./flowers.webp",      
      prompt: `a line art illustration for a coloring book page of a [SUBJECT] with [COLORING_BOOK_THEME], in the [COLORING_BOOK_ANIMATION] style, and [COLORING_BOOK_ART] style. Black outline only, white background, no shading, no color, no excessive detail. Ensure there is enough whitespace to guarantee a pleasurable coloring experience`,
      negativePrompt: "",      
      widthAndHeight: { width: 512, height: 768 },
    },
    // {
    //   id: "coloringbook",
    //   name: "Coloring Book",
    //   simpleName: "coloringbook",
    //   displayName: "Coloring Book",
    //   image: "/celticknots.webp",
    //   prompt: "A coloring book design tool",
    //   negativePrompt: "",
    //   widthAndHeight: { width: 512, height: 768 },
    // },
    {
      id: "yourphototocoloring",      
      name: "Your Photo to Coloring Page",
      simpleName: "yourphototocoloring",
      displayName: "Your Photo to Coloring Page",
      image: "beachcolor.webp",
      href: "/your-photo-to-coloring-page",
      prompt: "",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ pt: 4 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          {coloringOptions.map((option) => (
            <Grid item xs={12} sm={6} md={6} lg={5} key={option.id}>
              <Card                
                sx={{
                  height: '100%',                  
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: getBackgroundColor(option, selectedOption),
                  color: getTextColor(option, selectedOption),
                }}
              >
                <CardActionArea                  
                  onClick={!option.href ? ()=>handleChooseMerchandiseType(option) : null}                  
                  href={option.href}
                  sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    height="245"
                    image={option.image}
                    alt={option.displayName}
                    sx={{ objectFit: 'cover' }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: getFontWeight(option, selectedOption) }}
                    >
                      {option.displayName}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <div style={{ paddingTop: "1em" }} >            
            </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <Link to="https://createcoloringbooks.ai?src='md-landing">Rather create coloring books?</Link> */}
      <div style={{ paddingTop: "1em" }} >
            <Link to="https://createcoloringbooks.ai?src='md-create">Try creating a full coloring book</Link>
            </div>
    </Container>
  );
}