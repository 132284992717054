import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Paper,
  Avatar,
  CardMedia,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HeroButton from "../components/buttons/HeroButton";
import ScrollableCardMedia from "../components/ScrollableCardMedia";
import FooterNavLinks from "../components/navigation/FooterNavLinks";
import CommunityImageLinks from "../components/navigation/CommunityImageLinks";
import PricingPage from "./PricingPage";
import PublicAppBar from "../components/navigation/PublicAppBar";
import Categories from "../components/Categories";

const testimonials = [
  {
    name: "Connie",
    text: "Finally a way to make quality coloring pages for my classroom! My students love coloring them and I love the styling options.",
    image: "/landing/cindy.webp",
  },
  {
    name: "Jean-Guy",
    text: "I create t-shirt designs for bands and Amazon Merch, love the images I get and the simplicity of the prompting needed!",
    image: "/landing/jean-guy.webp",
  },
];

const JoinHero = () => {
  const [showPricing, setShowPricing] = useState(false);
  const categoriesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToCategories) {
      categoriesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <PublicAppBar showPricing={showPricing} setShowPricing={setShowPricing} />
      {showPricing && <PricingPage showHeader={false} />}
      {!showPricing && (
        <Container maxWidth="lg">
          <Box my={4} textAlign="center">
            <Typography variant="h2" component="h1" gutterBottom>
              Unleash Your Creativity with MerchDesign AI
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Create Stunning Coloring Pages and Designs for Amazon KDP,
              Classrooms, and More
            </Typography>
            <HeroButton message="Get Started Today" />
            <div style={{ paddingTop: "1em" }} >
            <Link to="https://createcoloringbooks.ai?src='md-landing">Rather create coloring books?</Link>
            </div>
          </Box>
          <ScrollableCardMedia hero="combined_coloring_hero_image_1.webp" />

          <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>
              Why Choose MerchDesign AI?
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  feature:
                    "Superior Image Generation: Create detailed, engaging coloring pages that surpass other platforms in quality.",
                  image: "/landing/beachmansion.webp",
                },
                {
                  feature:
                    "Easy Image Editing: Refine your designs effortlessly with intuitive editing tools.",
                  image: "/landing/flowers.gif",
                },
                {
                  feature:
                    "AI Vision Generated Captions: Enhance your designs with perfect captions.",
                  image: "/landing/slothcaption.webp",
                },
                {
                  feature:
                    "Versatile Design Tools: Create sticker and t-shirt designs for Amazon Merch and more.",
                  image: "/landing/cutesticker.webp",
                },
                {
                  feature:
                    "SEO Listing Creator: Optimize your Amazon Merch listings to attract more customers.",
                  image: "/landing/merchlistingseo.webp",
                },
                {
                  feature:
                    "Coloring Book Generation: Turn projects into ready to upload KDP manuscripts.",
                  image: "/landing/book.webp",
                },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper elevation={3} style={{ padding: 16 }}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "64px" }}
                    >
                      <Typography>{item.feature}</Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      height="240px"
                      sx={{
                        minHeight: "240px",
                        maxHeight: "240px",
                        objectFit: "contain",
                      }}
                      image={item.image}
                      alt={`Feature ${index + 1}`}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box my={4} textAlign="center" sx={{ paddingTop: "2em" }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Expand Your Design Horizons
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              MerchDesign AI isn’t just for coloring pages. Our versatile tools
              allow you to create high-quality sticker and t-shirt designs,
              perfect for expanding your product line and reaching new
              customers.
            </Typography>
            {/* <Button variant="contained" sx={{backgroundColor:"#ff6f00"}} size="large"> */}
            <Button
              href="/free-coloring-pages"
              variant="contained"
              sx={{
                "&:hover": {
                  backgroundColor: "#08b2cd",
                },
                marginTop: "1em",
                fontWeight: "800",
                letterSpacing: "2px",
                backgroundColor: "#ff6f00",
                borderRadius: "100px",
              }}
              size="large"
            >
              Explore More Designs
            </Button>
          </Box>
          <ScrollableCardMedia hero="combined_hero_image.webp" />

          <Box my={4} sx={{ paddingTop: "2em" }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              What Our Users Say
            </Typography>
            <Grid container spacing={4}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper elevation={3} style={{ padding: 16 }}>
                    <Box display="flex" alignItems="center">
                      <Avatar alt={testimonial.name} src={testimonial.image} />
                      <Box ml={2}>
                        <Typography variant="body1">
                          {testimonial.text}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {testimonial.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box my={4} textAlign="center" sx={{ paddingTop: "2em" }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Ready to Create Amazing Designs?
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              Sign up for MerchDesign AI today and start creating masterpieces
              that capture attention and drive results.
            </Typography>
            <HeroButton message="Sign Up Now" />
          </Box>
        </Container>
      )}
      <Box mt={8} py={2} bgcolor="grey.200">
        <Container maxWidth="lg">
          <FooterNavLinks />
          <CommunityImageLinks />
        </Container>
      </Box>
      <div ref={categoriesRef}>
      <Categories  />
      </div>
      <Box mt={1} mb={1} py={2}>
        <Typography variant="body2" color="textSecondary" align="center">
          &copy; 2024 MerchDesign AI. All rights reserved.
        </Typography>
      </Box>
    </>
  );
};

export default JoinHero;
